<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card class="elevation-12 mt-12 mb-6">
        <v-card-title class="Article">
            Article
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-simple-table>
                <tr>
                  <td>Brand</td>
                  <td>
                    {{ article.brandName }}
                    <v-tooltip v-model="showBrandTooltip" :open-on-hover="false" :absolute="true" right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon @click="on.click" @blur="on.blur" retain-focus-on-click size="1.2em">mdi-information</v-icon>
                      </span>
                    </template>
                    <span><brand :brand-no="article.brandNo" /></span>
                  </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td>GenArt (std)</td>
                  <td>
                    {{ article.genart }} 
                    <v-tooltip v-model="showGenartTooltip" :open-on-hover="false" :absolute="true" right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon @click="on.click" @blur="on.blur" retain-focus-on-click  size="1.2em">mdi-information</v-icon>
                      </span>
                    </template>
                    <span><genart :brand-no="article.brandNo"  :art-no="article.artNo"  /></span>
                  </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td>EAN</td>
                  <td>{{ article.ean }}</td>
                </tr>
                <tr>
                  <td>Trade Numbers</td>
                  <td>{{ article.refcoms }}</td>
                </tr>
                <tr>
                  <td>Replaced by</td>
                  <td>
                    <p class="mb-0" v-for="(replaced, i) in article.replacedBy" :key="i">
                      <a @click="searchArticle(replaced)">{{ replaced.artNo }}</a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Replaces</td>
                  <td>
                    <p class="mb-0" v-for="(replace, i) in article.replaces" :key="i">
                      <a @click="searchArticle(replace)">{{ replace.artNo }}</a>
                    </p>
                  </td>
                </tr>
                <tr v-if="article.status == null">
                  <td>Status</td>
                  <td>
                    Normal
                  </td>
                </tr>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-simple-table>
                <tr>
                  <td>Reference</td>
                  <td>{{ article.artNo }}</td>
                </tr>
                <tr>
                  <td>Countries</td>
                  <td>{{ article.countries }}</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>
                    <p class="mb-0" v-for="(price, i) in article.prices" :key="i">
                      {{ price.countries }} : {{ displayPrice(price.price) }} {{ price.currency }} ({{ price.validityDate }})
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Kit contains</td>
                  <td>
                    <v-simple-table v-if="article.kitContains != null" class="noBorder">
                      <tr>
                        <th>Kind</th>
                        <th>Quantity</th>
                        <th>Reference</th>
                      </tr>
                      <tr v-for="(part, i) in article.kitContains" :key="i">
                        <td>{{ part.genart }}</td>
                        <td>{{ part.quantity }}</td>
                        <td><a @click="searchArticle(part)">{{ part.artNo }}</a></td>
                      </tr>
                    </v-simple-table>
                  </td>
                </tr>
                <tr>
                  <td>Contained in kit</td>
                  <td>
                    <p class="mb-0 pt-1 pb-1" v-for="(kit, i) in article.partOfKits" :key="i">
                      <a @click="searchArticle(kit)">{{ kit.artNo }}</a>
                    </p>
                  </td>
                </tr>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-simple-table v-if="article.status != null" class="mt-6 statusTable">
            <tr>
              <th>Countries</th>
              <th style="width: 80px;">Quantity</th>
              <th style="width: 80px;">Quantity per Unit</th>
              <th>Status</th>
              <th style="width: 100px;">Valid from</th>
            </tr>
            <tr v-for="(stat, i) in article.status" :key="i">
              <td>{{ stat.countries }}</td>
              <td style="text-align: center;">{{ stat.quantityUnit }}</td>
              <td style="text-align: center;">{{ stat.quantityPerUnit }}</td>
              <td>{{ stat.status }}</td>
              <td style="text-align: center;">{{ stat.validityDate }}</td>
            </tr>
          </v-simple-table>
        </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <oerefs-list :is-full-list="true"></oerefs-list>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <articleequivs-list :is-full-list="true"></articleequivs-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ktyps-list :is-full-list="true"></ktyps-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <techinfos-list :is-full-list="true"></techinfos-list>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <moreinfos-list :is-full-list="true"></moreinfos-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import path from "../../shared/paths";

import OerefsList from '../oeref/OerefsList.vue';
import TechinfosList from './TechinfosList.vue';
import ArticleequivsList from './ArticleequivsList.vue';
import KtypsList from '../ktyp/KtypsList.vue';
import MoreinfosList from './MoreinfosList.vue';
import Brand from './Brand.vue';
import Genart from './Genart.vue';

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  VCol,
  VContainer,
  VIcon,
//   VForm,
  VRow,
  //VSpacer,
//   VTextField,
  VTooltip,
} from "vuetify/lib";


export default {
  name: 'article-details',

  components: {
    //VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    VCol,
    VContainer,
    // VForm,
    VRow,
    VTooltip,
    VIcon,
    //VSpacer,
    // VTextField,
    OerefsList,
    TechinfosList,
    MoreinfosList,
    KtypsList,
    ArticleequivsList,
    Brand,
    Genart,
  },

  props: {
      article: { type: Object, default: null },
  },
  
  methods: {
    displayPrice(price) {
      return price.toFixed(2);
    },
    searchArticle(articleToSearch) {
      this.$router.push({
        name: path.TECDOCARTICLE,
        query: { brandno: articleToSearch.brandNo, artno: articleToSearch.artNo },
      });
    },
  },

  data() {
    return {
      oerefs: null,
      showBrandTooltip: false,
      showGenartTooltip: false
    }
  },

  created() {
    
  }
}

</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

td:first-child {
  white-space: nowrap;
}

/* th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
} */

.noBorder td {
  padding: 6px !important;
  border-bottom: 0px !important;
}

.noBorder td:not(:last-child) {
  width: 1px;
}

.noBorder th:not(:last-child), td:not(:last-child) {
    border-right: 0px;
}

.noBorder th {
  text-align: left;
  border-bottom: 1px dotted #b2cbfa !important;
  padding: 6px;
}

.statusTable td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  border-top: 1px solid #b2cbfa !important;
  border-bottom: 0px !important;
}

</style>
