<template>
    <v-card class="elevation-12 mt-6">
        <v-card-title v-if="isDataTableLoading == true">
            Searching KTyps
        </v-card-title>
        <v-card-title v-else-if="ktyps.length > 1">
            {{ ktyps.length }} KTyps found
        </v-card-title>
        <v-card-title v-else-if="ktyps.length == 0">
            No KTyps found
        </v-card-title>
        <v-card-title v-else>
            {{ ktyps.length }} KTyp found
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-text-field
                class="pl-4 pr-4"
                v-model="ktypSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>

            <v-data-table
                class="mt-4"
                :items="ktyps"
                :headers="headers"
                :search="ktypSearch"
                sort-by1="ktypnr"
                sort-desc1
                :loading="isDataTableLoading"
                loading-text="Loading... Please wait"
                dense
            >
            <template v-slot:item.ktypInfo="{ item }">
              <span :title="item.ktypInfo.replaceAll('|', '\r\n')" v-if="item.ktypInfo != ''"><v-icon size="1.2em">mdi-information</v-icon></span>
              <!-- <span v-for="(info, i) in item.ktypInfo.split('|')" :key="i" :title="info.replace('|', '\r\n')">{{ info.split(':')[1] }}<br/></span> -->
            </template>
            <template v-slot:item.controls="props">
              <v-icon  @click="searchKtyp(props.item)">mdi-eye</v-icon>
            </template>
            </v-data-table> 

        </v-card-text>
    </v-card>

</template>

<script>
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";

import {
  VCard,
  VCardText,
  VCardTitle,
  VDivider,
  VDataTable,
  VIcon,
  VTextField,
} from "vuetify/lib";

export default {
  name: 'ktyps-list',

  components: {
    VCard,
    VCardText,
    VCardTitle,
    VDivider,
    VDataTable,
    VIcon,
    VTextField,
  },

  props: {
    isFullList: { type: Boolean, default: false },
    ktypsSearched: { type: Array, default: null }
  },

  data() {
    return {
      isDataTableLoading: false,
      ktypSearch: '',
      ktyps: this.ktypsSearched == null ? [] : this.ktypsSearched,
    }
  },

  computed: {
    headers: function () {
        if (this.isFullList == true) {
            return [
                { value: "kTypNo", text: "KTypNo" },
                { value: "kba", text: "KBA" },
                { value: "name", text: "Name" },
                { value: "ktypInfo", text: "Info" },
                { value: "controls", text: "", sortable: false },
            ];
        }
        else {
            return [
                { value: "kTypNo", text: "KTypNr" },
                { value: "kba", text: "KBA", align: ' d-none'},
                { value: "name", text: "Name", align: ' d-none' },
                { value: "manufacturer", text: "Manufacturer" },
                { value: "model", text: "Model" },
                { value: "version", text: "Version" },
                { value: "cc", text: "Cc" },
                { value: "hp", text: "Hp" },
                { value: "engcode", text: "EngCode" },
                { value: "date", text: "Date" },
                { value: "origin", text: "Origin" },
                { value: "controls", text: "", sortable: false },
            ];
        }
    },
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },

  methods: {
    searchKtyp(ktyp) {
      this.$router.push({
        name: path.TECDOCKTYP,
        query: { ktypno: ktyp.kTypNo },
      });
    },
    loadDataTable() {
      if (this.isFullList == true) {
        this.isDataTableLoading = true;

        let params = {
            kind: "articlektypsearch",
            brandNo: parseInt(this.$route.query.brandno),
            artNo: this.$route.query.artno,
            lang: this.storedLang
        }

        api
        .post("/articlektypsearch/search", params)
        .then((res) => {
            if (res.data.status === 200 && res.data.entity !== null) {
                if (res.data.entity.ktyps == null) {
                    this.ktyps = []
                }
                else {
                    this.ktyps = res.data.entity.ktyps;
                }
            } else {
                console.log(new Error("Result status : "+res.data.status));
                console.log(res);
            }
            this.isDataTableLoading = false;
        })
        .catch((err) => {
            console.log(new Error("Catching error : "));
            console.log(err);
            this.isDataTableLoading = false;
        });
      }
    }
  },

  created() {
      this.loadDataTable();
  },

  watch: {
    // '$route.query.artno': function () {
    //   this.loadDataTable();
    // }
  },
}

</script>

<style scoped>
tr:not(:last-child){
    border-bottom: 1px solid #b2cbfa !important;
}
td{
    border-bottom: inherit !important;
}
th {
    border-bottom: 2px solid #b2cbfa !important;
    font-size: 1.2em !important;
}
th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}

.v-icon:hover{
    cursor: pointer;
}
</style>
