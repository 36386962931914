<template>

    <v-card class="elevation-12 mt-6">
        <v-card-title v-if="isDataTableLoading == true">
            Searching Technical Information
        </v-card-title>
        <v-card-title v-else-if="techinfos.length > 1">
            {{ techinfos.length }} Technical Information found
        </v-card-title>
        <v-card-title v-else-if="techinfos.length == 0">
            No Technical Information found
        </v-card-title>
        <v-card-title v-else>
            {{ techinfos.length }} Technical Information found
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-text-field
                class="pl-4 pr-4"
                v-model="techinfoSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>

            <v-data-table
                class="mt-4"
                :items="techinfos"
                :headers="headers"
                :search="techinfoSearch"
                :loading="isDataTableLoading"
                loading-text="Loading... Please wait"
                dense
            >
            </v-data-table> 

        </v-card-text>
    </v-card>

</template>

<script>
import api from "../../components/external/modules/shared/utils/api";

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  //VSimpleTable,
  VDataTable,
  //VSelect,
  //VCol,
  //VContainer,
//   VForm,
//   VRow,
  //VSpacer,
  VTextField,
} from "vuetify/lib";

export default {
  name: 'techinfos-list',

  components: {
    // VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    //VSimpleTable,
    VDataTable,
    //VSelect,
    // VCol,
    //VContainer,
    // VForm,
    // VRow,
    //VSpacer,
    VTextField,
  },

  props: {
    
  },

  data() {
    return {
      isDataTableLoading: false,
      techinfos: [],
      techinfoSearch: '',
    }
  },

  computed: {
    headers: function () {
        return [
            { value: "techinfoTypeName", text: "Criterion" },
            { value: "techinfoValue", text: "Value" },
        ];
    },
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },

  methods: {
    loadDataTable() {
        this.isDataTableLoading = true;

        let params = {
            kind: "articletechinfosearch",
            brandNo: parseInt(this.$route.query.brandno),
            brandName: "",
            artNo: this.$route.query.artno,
            lang: this.storedLang
        }

        api
        .post("/articletechinfosearch/search", params)
        .then((res) => {
            if (res.data.status === 200 && res.data.entity !== null) {
                if (res.data.entity.techinfos == null) {
                    this.techinfos = []
                }
                else {
                    this.techinfos = res.data.entity.techinfos;
                }
            } else {
                console.log(new Error("Result status : "+res.data.status));
                console.log(res);
            }
            this.isDataTableLoading = false;
        })
        .catch((err) => {
            console.log(new Error("Catching error : "));
            console.log(err);
            this.isDataTableLoading = false;
        });
      
    }
  },

  created() {
      this.loadDataTable();
  },

  watch: {
    '$route.query.artno': function () {
      this.loadDataTable();
    }
  },
}

</script>

<style scoped>

</style>
