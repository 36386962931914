<template>
  <div>
    <v-card v-for="(genart, i) in genarts" :key="i">
        <v-card-title class="Genart">
            Genart
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
              <v-simple-table>
                <tr>
                  <td>Genart No</td>
                  <td>{{ genart.genartNo }}</td>
                </tr>
                <tr>
                  <td>Genart Name</td>
                  <td>{{ genart.genartName }}</td>
                </tr>
                <tr>
                  <td>Genart Normalized Name</td>
                  <td>{{ genart.genartNormalizedName }}</td>
                </tr>
                <tr>
                  <td>Assembly Group Name</td>
                  <td>{{ genart.assemblyGroupName }}</td>
                </tr>
                <tr>
                  <td>Usage Name</td>
                  <td>{{ genart.usageName }}</td>
                </tr>
                <tr>
                  <td>Synonyms</td>
                  <td>{{ genart.synonyms }}</td>
                </tr>
              </v-simple-table>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "../../components/external/modules/shared/utils/api";

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  //VDataTable,
  //VSelect,
  //VCol,
  //VContainer,
//   VForm,
  //VRow,
  //VSpacer,
  //VTextField,
} from "vuetify/lib";

export default {
  name: 'genart',

  components: {
    // VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    //VDataTable,
    //VSelect,
    //VCol,
    //VContainer,
    // VForm,
    //VRow,
    //VSpacer,
    //VTextField,
  },

  props: {
    brandNo: { type: Number, default: 0 },
    artNo: { type: String, default: "" }
  },

  data() {
    return {
      genarts: {}
    }
  },

  methods: {
    
  },

  created() {
    let params = {
        kind: "articlesearch",
        brandNo: this.brandNo,
        artNo: this.artNo,
        lang: this.storedLang
    }

    api
        .post("/genart/search", params)
        .then((res) => {
        if (res.data.status === 200 && res.data.entity !== null) {
            if (res.data.entity == null) {
                this.genarts = []
            }
            else {
                this.genarts = res.data.entity.genarts;
            }
        } else {
            console.log(new Error("Result status : "+res.data.status));
            console.log(res);
        }
    })
    .catch((err) => {
        console.log(new Error("Catching error : "));
        console.log(err);
    });
  },

  computed: {
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },
}

</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
}

th:not(:last-child), td:not(:last-child) {
  border-right: 1px dotted #b2cbfa;
}

</style>
