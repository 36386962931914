<template>

    <v-card class="elevation-12 mt-6">
        <v-card-title v-if="isDataTableLoading == true">
            Searching OE references
        </v-card-title>
        <v-card-title v-else-if="oerefs.length > 1">
            {{ oerefs.length }} OE References found
        </v-card-title>
        <v-card-title v-else-if="oerefs.length == 0">
            No OE References found
        </v-card-title>
        <v-card-title v-else>
            {{ oerefs.length }} OE Reference found
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-text-field
                class="pl-4 pr-4"
                v-model="manufacturerSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>

            <v-data-table
                class="mt-4"
                :items="oerefs"
                :headers="headers"
                :search="manufacturerSearch"
                sort-by1="manName"
                sort-desc1
                :loading="isDataTableLoading"
                loading-text="Loading... Please wait"
                dense
            >
            <template v-slot:item.controls="props">
              <v-icon @click="searchOeref(props.item)">mdi-eye</v-icon>
            </template>
            </v-data-table> 

        </v-card-text>
    </v-card>

</template>

<script>
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";

import {
  VCard,
  VCardText,
  VCardTitle,
  VDivider,
  VDataTable,
  VIcon,
  VTextField,
} from "vuetify/lib";

export default {
  name: 'oerefs-list',

  components: {
    VCard,
    VCardText,
    VCardTitle,
    VDivider,
    VDataTable,
    VIcon,
    VTextField,
  },

  props: {
    isFullList: { type: Boolean, default: false },
    oerefsSearched: { type: Array, default: null }
  },

  data() {
    return {
      isDataTableLoading: false,
      manufacturerSearch: '',
      oerefs: this.oerefsSearched == null ? [] : this.oerefsSearched,
    }
  },

  computed: {
    headers: function () {
        if (this.isFullList == true) {
            return [
                { value: "manName", text: "Manufacturer" },
                { value: "refNo", text: "Reference" },
                { value: "countries", text: "Countries" },
                { value: "controls", text: "", sortable: false },
            ];
        }
        else {
            return [
                { value: "manName", text: "Manufacturer" },
                { value: "refNo", text: "Reference" },
                { value: "controls", text: "", sortable: false },
            ];
        }
    },
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },

  methods: {
    searchOeref(oeref) {
      this.$router.push({
        name: path.TECDOCOEREF,
        query: { manno: oeref.manNo, refno: oeref.refNo },
      });
    },
    loadDataTable() {
      if (this.isFullList == true) {
        this.isDataTableLoading = true;

        let params = {
            kind: "articleoerefsearch",
            brandNo: parseInt(this.$route.query.brandno),
            brandName: "",
            artNo: this.$route.query.artno,
            lang: this.storedLang
        }

        api
        .post("/articleoerefsearch/search", params)
        .then((res) => {
            if (res.data.status === 200 && res.data.entity !== null) {
                if (res.data.entity.oerefs == null) {
                    this.oerefs = []
                }
                else {
                    this.oerefs = res.data.entity.oerefs;
                }
            } else {
                console.log(new Error("Result status : "+res.data.status));
                console.log(res);
            }
            this.isDataTableLoading = false;
        })
        .catch((err) => {
            console.log(new Error("Catching error : "));
            console.log(err);
            this.isDataTableLoading = false;
        });
      }
    }
  },

  created() {
      this.loadDataTable();
  },

  watch: {
    '$route.query.artno': function () {
      this.loadDataTable();
    }
  },
}

</script>

<style scoped>
tr:not(:last-child){
    border-bottom: 1px solid #b2cbfa !important;
}
td{
    border-bottom: inherit !important;
}
th {
    border-bottom: 2px solid #b2cbfa !important;
    font-size: 1.2em !important;
}
th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}

.v-icon:hover{
    cursor: pointer;
}
</style>
