<template>
    <v-card>
        <v-card-title class="Brand">
            Brand
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-simple-table>
              <tr>
                <td>Brand No</td>
                <td>{{ brand.brandNo }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ brand.brandName }}</td>
              </tr>
              <tr>
                <td>Name 2</td>
                <td>{{ brand.brandName2 }}</td>
              </tr>
              <tr>
                <td>Street</td>
                <td>{{ brand.street }}</td>
              </tr>
              <tr>
                <td>Street 2</td>
                <td>{{ brand.street2 }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ brand.city }}</td>
              </tr>
              <tr>
                <td>City 2</td>
                <td>{{ brand.city2 }}</td>
              </tr>
              <tr>
                <td>Post Code</td>
                <td>{{ brand.postCode }}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{{ brand.country }}</td>
              </tr>
              <tr>
                <td>Telephone Number</td>
                <td>{{ brand.tel }}</td>
              </tr>
              <tr>
                <td>Fax Number</td>
                <td>{{ brand.fax }}</td>
              </tr>
              <tr>
                <td>Email address</td>
                <td>{{ brand.email }}</td>
              </tr>
              <tr>
                <td>Website</td>
                <td><a :href="brand.website" target="_blank">{{ brand.website }}</a></td>
              </tr>
              
            </v-simple-table>
        </v-card-text>
    </v-card>

</template>

<script>
import api from "../../components/external/modules/shared/utils/api";

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  //VDataTable,
  //VSelect,
  //VCol,
  //VContainer,
//   VForm,
//   VRow,
  //VSpacer,
  //VTextField,
} from "vuetify/lib";

export default {
  name: 'brand',

  components: {
    // VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    //VDataTable,
    //VSelect,
    // VCol,
    //VContainer,
    // VForm,
    // VRow,
    //VSpacer,
    //VTextField,
  },

  props: {
    brandNo: { type: Number, default: 0 }
  },

  data() {
    return {
      brand: {}
    }
  },

  methods: {
    
  },

  created() {
    let params = {
        kind: "articlesearch",
        brandNo: this.brandNo,
        lang: this.storedLang
    }

    api
        .post("/brand/search", params)
        .then((res) => {
        if (res.data.status === 200 && res.data.entity !== null) {
            if (res.data.entity == null) {
                this.brand = []
            }
            else {
                this.brand = res.data.entity;
            }
        } else {
            console.log(new Error("Result status : "+res.data.status));
            console.log(res);
        }
    })
    .catch((err) => {
        console.log(new Error("Catching error : "));
        console.log(err);
    });
  },

  computed: {
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },
}

</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
}

th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}
</style>
